import React from "react";
import { genImagePath } from "../../utils/genImagePath";
import style from "./previewImg.module.css";

export default function previewImg({isClose, url, isCloseFn}) {
  return (
    <div>
      {
        isClose && (
          <>
            <div className={style.shade}></div>
            <div className={style.preLookWrap}>
              <img className={style.close} src={require("../../img/icon_close.png")} onClick={() => isCloseFn(false)} alt=""/>
              <img src={genImagePath(url)} alt=""/>
            </div>
          </>
        )
      }
    </div>
  );
}
