import React, { useState, useEffect } from "react";
import { Link, navigateTo } from "gatsby";
import axios from "../../../utils/request";
import PublisherHeader from "../../../components/publish-header";
import { genImagePath } from "../../../utils/genImagePath";
import Cursor from "../../../components/cursor";
import PreviewImg from "../../../components/preview-img";
import style from "./verify.module.css";

export default ({ location }) => {
  const [userInfo, setUserInfo] = useState({});
  const [showReason, setShowReason] = useState(false);
  const [preLook, setPreLook] = useState(false);

  function isImage(item) {
    const name = item.name.toLowerCase();
    return name.endsWith("png") || item.name.endsWith("jpg") || name.endsWith("jpeg");
  }

  function handleLook(item) {
    if (isImage(item)) {
      setPreLook(true);
    } else {
      let link = document.createElement("a");
      link.href = genImagePath(item.url).replace("-internal.aliyuncs", ".aliyuncs");
      link.target = "__blank";
      document.body.appendChild(link);
      link.click();
      console.log(link.href);
      document.body.removeChild(link);
    }
  }

  useEffect(() => {
    async function getDetail() {
      const { data } = await axios({
        method: "get",
        url: "/designer/userinfo",
        headers: { "Content-Type": "application/json;charset=UTF-8" },
      });
      if (data) setUserInfo(data);
      if (data?.contractStatus === 0) navigateTo("/designerPress/contract");
    }
    getDetail();
  }, []);

  useEffect(() => {
    const files = document.querySelectorAll("#fileName i:first-child");
    const ellipsis = document.querySelectorAll("#fileName i:last-child");
    for (let i = 0; i < files.length; i++) {
      if (files[i].offsetHeight >= 36) {
        ellipsis[i].style.display = "block";
      }
    }
  });

  return (
    <div>
      <Cursor></Cursor>
      <PublisherHeader pathName={location.pathname}></PublisherHeader>
      <div className={style.verifyBox}>
        <div className={style.lineOne}>
          <h2>ICY X {userInfo?.username}</h2>
          <div className={style.status}>
            {userInfo?.status === 0 && (
              <span style={{ color: "#FA6400" }}>审核中</span>
            )}
            {userInfo?.status === 1 && (
              <span style={{ color: "#6DD400" }}>审核通过</span>
            )}
            {userInfo?.status === 2 && (
              <>
                <span style={{ color: "#E02020" }}>审核未通过</span>
                <i
                  onMouseEnter={() => setShowReason(true)}
                  onMouseLeave={() => setShowReason(false)}
                >
                  原因
                  {showReason && (
                    <div className={style.reason}>{userInfo?.reason}</div>
                  )}
                </i>
              </>
            )}
          </div>
        </div>
        <div className={style.lineTwo}>
          <h3>
            NAME<span>{userInfo?.username}</span>
          </h3>
          <h4>
            联系方式
            <span>{userInfo?.phone}</span>
            {userInfo?.status === 1 && (
              <i className={style.redWord}>
                *请保持手机畅通，我们的BD专员将会尽快与您联系
              </i>
            )}
          </h4>
        </div>
        <div className={style.lineThree}>
          <h3>
            品牌名<span>{userInfo?.brand}</span>
          </h3>
          <h4>
            微信号
            <span>{userInfo?.wechat}</span>
          </h4>
        </div>
        <div className={style.lineFour}>
          <div className={style.lineFourLeft}>
            <h5>简介</h5>
            <div className={style.wordWrap}>
              <span>{userInfo?.introduction}</span>
            </div>
            {userInfo?.status === 0 && (
              <Link to="/designerPress/register?edit=1" className={style.modifyButton}>
                修改申请信息
              </Link>
            )}
            {userInfo?.status === 2 && (
              <Link to="/designerPress/register?edit=1" className={style.modifyButton}>
                重新提交申请
              </Link>
            )}
          </div>
          <div className={style.lineFourRight}>
            <div className={style.email}>
              邮箱<span>{userInfo?.email}</span>
            </div>
            <ul className={style.fileList}>
              {userInfo?.files?.map((item, i) => (
                <li key={i}>
                  <div className={style.fileTop}>
                    <div className={style.mask}>
                      <img
                        src={require("../../../img/icon_eye.png")}
                        onClick={() => handleLook(item)}
                        width="22"
                        height="15"
                        alt="" />
                      {/* <a href={genImagePath(item.url)} download>下载</a> */}
                    </div>
                    {item.name.slice(item.name.lastIndexOf(".") + 1).toUpperCase()}
                  </div>
                  <div className={style.fileName} id="fileName">
                    <i>{item.name}</i>
                    <i>
                      ...
                      {item.name.slice(item.name?.lastIndexOf("."))}
                    </i>
                  </div>
                  <PreviewImg isClose={preLook} isCloseFn={setPreLook} url={item.url}></PreviewImg>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
